<template>

  <div >
    <div v-if="showIfRefProduct">
        <div v-for="otherFeature in otherFeatures" v-bind:key="otherFeature.type">
          <h4 class="subtitle-form">{{ otherFeature.type }}</h4>

          <b-select
          size="sm"
          :options="otherFeature.features"
          text-field="designation"
          value-field="id"
          :value="otherFeaturesToPatch[otherFeature.type]"
          @input="selectedFeature => updateOtherFeature(selectedFeature, otherFeature.type)"
        ></b-select>
        </div>
    </div>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import ProductSelectionMixin from "@/mixins/ProductSelectionMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [DetailUpdateMixin, ProductSelectionMixin],
  data(){
    return {
      otherFeaturesToPatch: {},
      name: "",
      previousRefproduct: null,
    }
  },
  async created(){
    if(this.currentEntry.otherFeatures){
      this.currentEntry.otherFeatures.map(feature => {
        this.otherFeaturesToPatch[feature.type] = feature.id;
      });
    }

    if(this.refProduct_id && this.refProduct_id != null){
      await this.$store.dispatch("getOtherFeatures", this.refProduct_id);
    }
  },
  methods: {

    async updateOtherFeature(feature_id, type){
      if(feature_id != undefined ) {
        this.otherFeaturesToPatch[type] = feature_id;

        let otherFeaturesToPatchClone = [];

        Object.keys(this.otherFeaturesToPatch).forEach(key => {
          if(!otherFeaturesToPatchClone.includes({id: this.otherFeaturesToPatch[key]})){
            otherFeaturesToPatchClone.push({
            id: this.otherFeaturesToPatch[key]
            })
          }
        })
        await this.$store.dispatch(this.storeEditAction, {
          otherFeatures: otherFeaturesToPatchClone
        });
        // update name everytime specificity is updated
        await this.updateName();
      }
    },
    async updateName(){
        let featuresToJoin = [];
        if(this.currentProduct.name){
          featuresToJoin.push(this.currentProduct.name);
        }
        else if(this.currentEntry.name){
          featuresToJoin.push(this.currentEntry.refProduct_name);
        }

        if(this.currentEditedEntry.otherFeatures && this.currentEditedEntry.otherFeatures.length > 0){
          this.otherFeatures.map(otherFeature => {
           let tempFeature = otherFeature.features.filter(r => this.currentEditedEntry.otherFeatures.find(feature => feature.id == r.id));
           featuresToJoin.push(tempFeature[0].designation);
          })
        }
        this.name = featuresToJoin.join(' ');
        await this.$store.dispatch(this.storeEditAction, {
                name: this.name
        });
    }
  },
  computed: {
    ...mapGetters({
      refProduct_id: "inputStock/refProduct_id",
      otherFeatures: "otherFeatures",
      currentProduct: "currentProduct",
    }),
    showIfRefProduct(){
      if(this.currentEntry.otherFeatures && this.currentEntry.otherFeatures.length > 0){
        return true;
      }
      else return this.otherFeatures.length > 0 ;
    }
  },
  watch: {
    async refProduct_id(){
      if(this.refProduct_id != null) await this.$store.dispatch("getOtherFeatures", this.refProduct_id);
      // reset the otherFeatures      
      if(this.previousRefproduct != null ){
        await this.$store.dispatch(this.storeEditAction, {
          otherFeatures: []
        });
        this.otherFeaturesToPatch = {};
      }
      this.previousRefproduct = this.refProduct_id;
    },
  }
};
</script>

<style lang="scss" scoped></style>
